<template>
  <div v-if="ready && connected">
    <div id="game-layout"  :style="`--game-layout-chat-height: ${chatHeight}px`">
      <div id="grid-top-bar" class="d-flex flex-row text-white">
        <div id="left-nav" class="d-flex flex-column">
          <div class="top-nav d-flex flex-row">
            <div class="nav-icon mx-3 d-flex align-items-center cursor-pointer" @click="toggleSideMenu">
              <img src="@/assets/menu.svg"/>
            </div>
            <div class="d-flex flex-row">
              <div class="d-flex flex-row text-uppercase align-items-center">
                <div class="me-4 w-100 text-break">{{ characterName }}</div>
                <div class="btn btn-primary btn-sm d-inline-block btn-warning text-black" v-if="isGuestAccount" @click="upgradeGuestAccount">Register</div>
              </div>
            </div>
            <div class="ms-auto d-flex justify-content-center align-items-center changelog" id="changelog">
              <div id="changelog-title">{{ latestUpdate }}</div>
            </div>
          </div>
          <div class="bottom-nav flex-row justify-content-around">
            <SkillIconBox skill="mining"/>
            <SkillIconBox skill="woodcutting"/>
            <SkillIconBox skill="fishing"/>
            <SkillIconBox skill="combat"/>
          </div>
        </div>
        <router-link to="/play" id="logo">
          <img src="@/assets/logo.png" alt="IdleCraft logo"/>
        </router-link>
        <div id="right-nav">
          <div class="top-nav d-flex flex-row align-items-center">
            <div class="ms-auto me-2">{{ players_online }} players online</div>
          </div>
          <div class="bottom-nav flex-row justify-content-around">
            <SkillIconBox skill="crafting"/>
            <SkillIconBox skill="smithing"/>
            <SkillIconBox skill="cooking"/>
            <SkillIconBox skill="refining"/>
          </div>
        </div>
      </div>
      <div id="grid-game">
        <router-view></router-view>
      </div>
      <div id="grid-chat" :class="{'open': chatIsOpen}">
        <Vue3DraggableResizable
        :resizable="!disableResize"
        :disabled="disableResize"
        :draggable="false"
        :handles="['tm']"
        :disabledX="true"
        :disabledY="true"
        :disabledW="true"
        @resize-start="resizeChatStart"
        @resizing="resizeChat"
        @resize-end="resizeChatEnd">
          <ChatBox/>
        </Vue3DraggableResizable>
      </div>
      <Side />
      <MobileNav></MobileNav>
    </div>

    <SideMenu/>
    <ItemContextMenu v-if="itemContextMenuIsOpen"></ItemContextMenu>
    <GlobalMessages />
    <UpgradeFromGuestDialogue v-if="upgradeFromGuestDialogue" />
  </div>
  <div v-else class="d-flex justify-content-center align-items-center flex-column vh-100 bg-dark text-white">
    <img src="@/assets/logo.png" width="150" class="mb-2" />
    <div>Loading your game</div>
    <p v-if="reconnectAttempts > 0">Attempting reconnect</p>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import ChatBox from "@/components/chat/ChatBox";
import SkillIconBox from "@/components/nav/SkillIconBox";
import ItemContextMenu from "@/components/items/ItemContextMenu";
import MobileNav from "@/components/nav/MobileNav";
import SideMenu from "@/components/nav/SideMenu";
import GlobalMessages from "@/components/GlobalToast";
import Side from "@/components/nav/RightSide/Side";
import {startCase} from "lodash";
import UpgradeFromGuestDialogue from "@/components/UpgradeFromGuestDialogue";

export default {
  name: "Play",
  components: {
    UpgradeFromGuestDialogue,
    Side, GlobalMessages, SideMenu, MobileNav, ItemContextMenu, SkillIconBox, ChatBox},
  data() {
    return {
      latestUpdate: 'loading',
      connected: false,
      reconnectAttempts: 0,
      headwayLoaded: false,
      chatHeight: 200,
      disableResize: false,
    }
  },
  sockets: {
    NEW_TAB_OPENED() {
      this.ready = false;
    },
    'connect'() {
      this.connected = true;
    },
    'disconnect'() {
      this.connected = false;
    },
  },
  created() {

  },
  mounted() {

    this.connected = this.$socket.connected;

    this.$socket.io.on('reconnect', () => {
      this.reconnectAttempts = 0;
      this.connected = true;
      this.$socket.emit("get chat history")
    })

    this.$socket.io.on('reconnect_attempt', () => {
      this.reconnectAttempts++;

    })

    this.updateSiteTitle(this.activeSkillZone)

    const headway = setInterval(() => {
      const element = document.getElementById("changelog");

      if(element) {
        const _self = this;

        const HW_config = {
          selector: ".changelog", // CSS selector where to inject the badge
          account: "7XGzpx",
          callbacks: {
            onWidgetReady: function (widget) {
              let title = widget.getChangelogs()[0].title;

              if (title.length > 50)
                title = title.substr(0, 47) + '...'

              _self.latestUpdate = title;
            },
            onWidgetUpdate: function (widget) {
              let title = widget.getChangelogs()[0].title;

              if (title.length > 50)
                title = title.substr(0, 47) + '...'

              _self.latestUpdate = title;
            }
          }
        };
        // eslint-disable-next-line no-undef
        Headway.init(HW_config)

        clearInterval(headway);

        this.$socket.emit('players online')
      }
    }, 500);

    window.addEventListener('resize', () => {
      if(window.innerWidth < 993) {
        this.disableResize = true;
      } else {
        this.disableResize = false;
      }
    });

    if(window.innerWidth < 993) {
      this.disableResize = true;
    }

  },
  watch: {
    activeSkillZone(current) {
      this.updateSiteTitle(current)
    }
  },
  computed: {
    ...mapGetters({
      getHead: "user/getMCHead",
      itemContextMenuIsOpen: "layout/itemContextMenuIsOpen",
      chatIsOpen: "layout/chatIsOpen",
      inventoryIsOpen: "layout/inventoryIsOpen",
      sideMenuIsOpen: "layout/sideMenuIsOpen",
      characterName: "user/getCharacterName",
      currency: "user/currency",
      ready: "user/isReady",
      activeSkillZone: "user/activeSkillZone",
      isGuestAccount: "user/isGuestAccount",
      upgradeFromGuestDialogue: "layout/upgradeFromGuestDialogue"
    }),
    players_online() {
      return this.$store.state.config.players_online
    },
  },
  methods: {
    ...mapMutations({
      toggleSideMenu: "layout/toggleSideMenu"
    }),
    resizeChat({h}) {
      this.chatHeight = h;
    },
    resizeChatStart() {
      document.body.classList.add('disable-select')
    },
    resizeChatEnd() {
      document.body.classList.remove('disable-select')
    },
    updateSiteTitle(skill) {

      const defaultTitle = 'IdleCraft'

      if(skill)
        window.parent.document.title = `${startCase(skill.skill)} ${skill.name} ${defaultTitle}`
      else
        window.parent.document.title = `Idle - ${defaultTitle}`
    },
    upgradeGuestAccount() {
      this.$store.state.layout.upgradeFromGuestDialogue = true;
    }
  }
}
</script>