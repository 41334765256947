<template>
  <ul id="chat-message-list" ref="chatList" class="list-unstyled" @scroll="handleScroll">
    <TransitionGroup name="chat-message">
      <ChatMessage v-for="(message) in messages" :key="message.id" :message="message"/>
    </TransitionGroup>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";
import ChatMessage from "@/components/chat/ChatMessage";
import {nextTick} from "vue";

export default {
  name: "ChatMessages",
  components: {ChatMessage},
  data() {
    return {
      newMessageIndicator: false,
      disableAutoScroll: false,
    }
  },
  computed: {
    ...mapGetters({
      messages: "chat/messages",
      index: "chat/index"
    })
  },
  mounted() {
    this.scrollToBottom(500);
  },
  sockets: {
    NEW_CHAT_MESSAGE() {
      this.scrollToBottom()
    },
  },
  methods: {
    handleScroll() {
      nextTick(() => {
        const chatList = this.$refs.chatList;
        const atBottom = chatList.scrollTop >= chatList.scrollHeight - (chatList.clientHeight + 100)


        this.disableAutoScroll = !atBottom;

      })
    },
    scrollToBottom(timer = 100) {
      setTimeout(() => {

        if (this.$refs['chatList'] && !this.disableAutoScroll)
          this.$refs['chatList'].scrollTop = 9999
      }, timer)
    }
  }
}
</script>