<template>
  <div>
    <img src="/img/icons/heart.png" /> {{ health }} / {{ maxHealth }}
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters({
      health: "user/health",
      maxHealth: "user/maxHealth",
    })
  }
}
</script>