<template>
    <form action="" @submit.prevent="sendMessage" class="d-flex align-items-center">

      <div class="input-group">
        <span class="input-group-text bg-dark" id="basic-addon1">
          <img src="/img/items/Chat.png" width="24" height="24"/>
        </span>
        <input type="text" @input="updateMessage" class="form-control bg-dark" id="chat-input-box" :value="message" autocomplete="off">
      </div>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Chat',
  computed: {
    ...mapGetters({
      message: "chat/message"
    }),
  },
  methods: {
    sendMessage() {
      if (this.message.length > 140) {
        alert("Messages are limited to 140 characters, please shorten your message");
        return;
      }
      this.$socket.emit("chat send message", this.message);
      this.$store.commit('chat/UPDATE_MESSAGE', '')
    },
    updateMessage($event) {
      this.$store.commit('chat/UPDATE_MESSAGE', $event.target.value)
    }
  }
}
</script>