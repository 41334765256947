<template>
  <div class="d-flex flex-wrap overflow-auto w-100 flex-grow-1 align-items-center justify-content-center">
    <div class="w-100 d-flex align-items-end justify-content-center flex-wrap p-5">
      <div class="w-100 mb-5">
        <HealthBar class="text-end" />
        <Player />
      </div>
      <div id="equipment-left-panel" class="d-flex flex-row justify-content-between w-100">
        <EquipmentSlot type="helmet"/>
        <EquipmentSlot type="chestplate"/>
        <EquipmentSlot type="leggings"/>
        <EquipmentSlot type="boots"/>
      </div>
      <div id="equipment-right-panel" class="d-flex w-100 flex-row justify-content-around my-4">
        <EquipmentSlot type="weapon"/>
        <EquipmentSlot type="arrow"/>
      </div>
      <div id="equipment-tools" class="d-flex flex-row w-100 justify-content-between">
        <EquipmentSlot type="pickaxe"/>
        <EquipmentSlot type="fishing_rod"/>
        <EquipmentSlot type="axe"/>
      </div>
    </div>
  </div>
</template>
<script>
import EquipmentSlot from "@/components/nav/RightSide/EquipmentSlot";
import Player from "@/components/player/Player";
import HealthBar from "@/components/player/HealthBar.vue";
export default {
  name: 'Equipment',
  components: {HealthBar, Player, EquipmentSlot}
}
</script>