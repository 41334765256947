<template>
  <div id="side-menu" :class="{'open': sideMenuIsOpen}" class="d-flex flex-column ">
    <div class="d-flex flex-wrap text-white d-lg-none" @click="closeNav">
      <SkillIconBox v-for="(skill, index) in getSkills" :key="index" :skill="skill" class="p-2"/>
    </div>
    <ul class="list-unstyled mb-0 d-flex flex-fill flex-column">

      <li @click="closeNav"><a href="https://discord.gg/BMyngKgbNg" target="_blank" rel="nofollow">Discord</a></li>
      <li @click="closeNav"><a href="https://trello.com/b/4pE9f62g/idlecraft" target="_blank" rel="nofollow">Trello
        Roadmap</a></li>
      <li @click="closeNav">
        <router-link :to="{name: 'FAQ'}">FAQ</router-link>
      </li>
      <li @click="closeNav">
        <router-link :to="{name: 'Settings'}">Settings</router-link>
      </li>
      <li @click="closeNav">
        <a href="/terms-and-conditions.html" target="_blank">Terms and Conditions</a>
      </li>
      <li @click="closeNav">
        <a href="/privacy-policy.html" target="_blank">Privacy Policy</a>
      </li>
      <li @click="closeNav">
        <a href="/rules.html" target="_blank">Rules</a>
      </li>
      <li @click="logout" class="mt-auto"><a href="#">Logout</a></li>
    </ul>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import SkillIconBox from "@/components/nav/SkillIconBox";

export default {
  name: "SideMenu",
  components: {SkillIconBox},
  data() {
    return {}
  },
  methods: {
    ...mapMutations({
      closeNav: "layout/toggleSideMenu"
    }),

    logout() {
      if (confirm("Are you sure you want to logout?") === true) {
        localStorage.removeItem(process.env.VUE_APP_JWT_NAME)
        window.location.reload();
      }
    },
  },
  computed: {
    ...mapGetters({
      sideMenuIsOpen: "layout/sideMenuIsOpen",
      getSkills: "user/getSkills"
    })
  }
}
</script>