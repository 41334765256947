<template>
  <div id="chatbox" class="p-2" ref="chatBox">
    <ChatMessages />
    <ChatNewMessageInput :message="message" @update-message="updateMessage" />
  </div>
</template>
<script>
import ChatMessages from "@/components/chat/ChatMessages";
import ChatNewMessageInput from "@/components/chat/ChatNewMessageInput";
export default {
  components: {ChatNewMessageInput, ChatMessages},
  data() {
    return {
      message: '',
    }
  },
  mounted() {
    this.$socket.emit("get chat history")
  },
  sockets: {
    reconnected() {
    }
  },
  emits: ['whisper', 'mention'],
  methods: {
    updateMessage(message) {

      this.message = message;
    },
  }
}
</script>