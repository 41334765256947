<template>
  <nav id="mobile-nav">
    <ul class="list-unstyled mb-0">
      <li @click="closeAll">
        <router-link :to="{name: 'Home'}">
          <img src="/img/items/Home.png"/>
        </router-link>
      </li>
      <li @click="closeAll">
        <router-link :to="{name: 'Quest'}">
          <img src="/img/items/World.png"/>
        </router-link>
      </li>
      <li @click="toggleInventory">
        <a href="#">
          <img src="/img/items/Inventory.png"/>
        </a>
      </li>
      <li @click="toggleChat">
        <img src="/img/items/Chat.png"/>
      </li>
    </ul>
  </nav>
</template>
<script>
import {mapMutations} from "vuex";

export default {
  name: "MobileNav",
  methods: {
    ...mapMutations({
      toggleInventory: "layout/toggleInventory",
      toggleChat: "layout/toggleChat",
      closeAll: "layout/toggleMenuNavs"
    })
  },
  mounted() {
  }
}
</script>