<template>
  <div class="modal bg-dark bg-opacity-75" :class="{'show fade d-block': this.shouldShow}" tabindex="-1" @click="close">
    <div class="modal-dialog bg-dark" @click.stop>
      <div class="modal-content bg-dark border-dark text-light">
        <div class="modal-header border-0">
          <h5 class="modal-title d-flex justify-content-center align-items-center">
            <Item :item="item" :hide-quantity="true"/>
            {{ item.Item.name }}
          </h5>
          <button type="button" class="btn-close text-white bg-white" data-bs-dismiss="modal"
                  aria-label="Close"
                  @click="close"></button>
        </div>
        <div class="modal-body d-flex flex-column gap-2">
          <ul>
            <li v-for="(attr, index) in item.ItemAttributes" :key="index">
              {{ getAttributeName(attr.attribute) }}
              {{ getAttributeValue(attr.attribute, attr.value) }}
            </li>
          </ul>
          <template v-if="needsQuantity">
            Quantity to use: {{ quantityToUse }}

            <input type="range" min="1" :max="item.amount" v-model="quantityToUse"/>
          </template>
          <div class="mt-auto item-btn-list">
            <template v-if="canEquip">
              <button class="btn btn-primary" v-if="!item.equipped" @click="equip">Equip</button>
              <button class="btn btn-primary" v-if="item.equipped" @click="unequip">Un Equip</button>
            </template>
            <template v-if="canEat">
              <button class="btn btn-primary" @click="eat">Eat for {{ potentialHealth }} health</button>
            </template>
            <template v-if="canOpen">
              <button class="btn btn-primary" @click="open">Open</button>
            </template>
            <template v-if="canUseAsHeat">
              <button class="btn btn-primary" @click="consumeForHeat">Consume for {{ potentialHeat }} heat</button>
            </template>
            <template v-if="canCombatInventory">
              <button class="btn btn-primary" @click="addToCombatInventory">Add {{ quantityToUse }} to combat inventory
              </button>
            </template>
            <template v-if="canRefine">
              <button class="btn btn-primary" @click="refineItem">
                Refine
              </button>
            </template>
            <template v-if="canSell">
              <button class="btn btn-primary" @click="sell">
                <img src="/img/sprites/coin.png"/> Sell {{ quantityToUse }} for {{ value }}
              </button>
            </template>
            <template v-if="canTrade">
              <button class="btn btn-primary" @click="sellOnMarketplace">
                <img src="/img/sprites/coin.png"/> Sell on the marketplace
              </button>
            </template>
            <button class="btn btn-primary" @click="link">
              <img src="/img/items/Chat.png"/> Link in chat
            </button>
            <template v-if="item.Item.value === 0">
              <button class="btn btn-primary" @click="destroyItem">
                Destroy Item
              </button>
            </template>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Item from "@/components/items/Item";
import {startCase} from 'lodash'
import {mapGetters} from "vuex";

export default {
  name: 'ItemContextMenu',
  components: {Item},
  data() {
    return {
      quantityToUse: 1,
    }
  },
  methods: {
    close() {
      this.store.itemContextMenu.open = false;
    },
    equip() {
      this.$socket.emit('equip item', {
        item: this.item.id,
        amount: this.quantityToUse,
      });
      this.close();
    },
    unequip() {
      this.$socket.emit('unequip item', this.item.id);
      this.close();
    },
    open() {
      this.$socket.emit('auth:open item', {
        itemId: this.item.id,
        quantity: this.quantityToUse
      });
      this.close();
    },
    addToCombatInventory() {
      this.$socket.emit('add to combat inventory', {
        item: this.item.id,
        amount: this.quantityToUse
      });
      this.close();
    },
    eat() {
      this.$socket.emit('eat item', {
        item: this.item.id,
        amount: this.quantityToUse,
        i: this.item
      });
      this.close();
    },
    consumeForHeat() {
      this.$socket.emit('consume for heat', {
        item: this.item.id,
        amount: this.quantityToUse
      });
      this.close();
    },
    sell() {
      if (window.confirm("Are you sure you want to sell this item")) {
        this.$socket.emit('sell item', {
          item: this.item.id,
          amount: this.quantityToUse
        });
        this.close();
      }
    },
    getAttributeName(name) {
      return startCase(name);
    },
    getAttributeValue(attr, value) {


      if (attr.startsWith('levelRequirement')) {
        const name = value.split(':')

        return `${startCase(name[0])} ${name[1]}`
      }

      return startCase(value);

    },
    refineItem() {
      this.$socket.emit("auth:refine add item", this.item.id);
      this.$router.push({
        name: "Refining"
      });
      this.close();
    },
    link() {
      this.$store.commit("chat/ADD_ITEM", this.item);
      document.querySelector("#chat-input-box").focus()
      this.close();
    },
    sellOnMarketplace() {
      this.$router.push({
        name: "MarketplaceSellItem",
        query: {
          item_id: this.item.id,
          amount: this.quantityToUse,
        }
      })
      this.close();
    },
    destroyItem() {
      if(confirm("Are you sure you want to destroy this item?")) {
        this.$socket.emit("auth:destroy item", {
          itemId: this.item.id,
          amount: this.quantityToUse,
        });

        this.close();
      }
    }
  },
  computed: {
    ...mapGetters({
      staff: "user/staffLevel"
    }),
    value() {
      return new Intl.NumberFormat('en-gb').format(this.item.Item.value * this.quantityToUse)
    },
    store() {
      return this.$store.state.layout
    },
    shouldShow() {
      return this.store.itemContextMenu.open
    },
    item() {
      return this.store.itemContextMenu.item;
    },
    canEquip() {
      return this.item.Item.equippable
    },
    canEat() {
      return this.item.Item.can_eat
    },
    canOpen() {
      return this.item.Item.can_open
    },
    canTrade() {
      return this.item.Item.can_sell
    },
    canSell() {
      return this.item.Item.value && (this.item.Item.can_sell !== false)
    },
    canUseAsHeat() {
      return this.item.Item.heat > 0
    },
    canCombatInventory() {
      return this.item.Item.combat_inventory
    },
    potentialHealth() {
      return new Intl.NumberFormat().format(this.item.Item.health_given * this.quantityToUse)
    },
    potentialHeat() {
      return new Intl.NumberFormat().format(this.item.Item.heat * this.quantityToUse)
    },
    needsQuantity() {
      return this.canSell || this.canEat || this.canUseAsHeat
    },
    canRefine() {
      return this.item.Item.can_refine;
    }
  }
}
</script>
