<template>
  <div class="grid-inventory w-100" :class="{'hide-filter': hideFilter}">
    <div class="grid-inventory-filter" v-if="!hideFilter">
      <input type="text" v-model="filter" class="form-control bg-dark" placeholder="What are you looking for?">
    </div>
    <ul class="list-unstyled p-2 my-1 grid-inventory-inventory">
      <li v-for="(item) in items" :key="item.id" class="m-2 d-inline-block cursor-pointer"
          @click="clickActionForItem(item)">
        <Item :item="item" :size="52" :animate="true"/>
      </li>
    </ul>
  </div>
</template>
<script>
import Item from "@/components/items/Item";
import {mapGetters} from "vuex";

export default {
  name: "Inventory",
  components: {Item},
  data() {
    return {
      filter: '',
    }
  },
  props: {
    combat: {
      type: Boolean,
      default: false
    },
    hideFilter: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    items() {
      let items = this.inventory.filter(i => !i.equipped)
        .filter(i => !i.refine)
        .filter(i => i.combat_inventory === this.combat)
        .sort((a, b) => {

          if (a.Item.name > b.Item.name)
            return 1;

          if (a.Item.name < b.Item.name)
            return -1;

          return 0;
        });

      if (this.filter.length > 0) {
        return items.filter(i => {
          return i?.Item?.name.toLowerCase().includes(this.filter.toLowerCase());
        })
      }

      return items;
    },
    ...mapGetters({
      inventoryIsOpen: "layout/inventoryIsOpen",
      inventory: "user/inventory",
      inCombat: "combat/inCombat",
    }),
  },
  methods: {
    openContextMenu(item) {
      this.$store.state.layout.itemContextMenu.item = item;
      this.$store.state.layout.itemContextMenu.open = true;
    },
    clickActionForItem(item) {
      if (this.combat) {
        if (this.inCombat)
          this.$socket.emit("consume combat inventory", item.id)
        else
          this.$socket.emit("remove from combat inventory", item.id)
      } else {
        this.openContextMenu(item)
      }
    }
  }
}
</script>