<template>
  <div :id="getId" class="equipment-slot" :class="{'cursor-pointer': currentlyEquipped}">
    <McIcon :icon="getIcon" :class="{'item-unequipped': !currentlyEquipped}"
             v-if="!currentlyEquipped" :size="64"/>
    <Item :item="currentlyEquipped" v-if="currentlyEquipped" :hide-quantity="type !== 'arrow'" @click="unequip" :size="64" class="m-0"/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Item from "@/components/items/Item";
import McIcon from "@/components/McIcon";

export default {
  name: 'EquipmentSlot',
  components: {McIcon, Item},
  data() {
    return {
    }
  },
  props: {
    type: {
      type: String,
      required: true,
    }
  },
  methods: {
    unequip() {
      this.$socket.emit("unequip item", this.currentlyEquipped.id)
    }
  },
  computed: {
    ...mapGetters({
      inventory: "user/equippedItems"
    }),
    getId() {
      return `equipment-slot-${this.type}`
    },
    getIcon() {

      if (this.type === 'weapon')
        return `diamond-sword`;

      if(this.type === 'arrow')
        return 'arrow';

      return `diamond-${this.type}`
    },
    currentlyEquipped() {
      return this.inventory.find(s => {
        return s.Item.type === this.type
      });
    }
  }
}
</script>
