<template>
  <v-tooltip placement="bottom" class="d-flex flex-fill border-1 border-opacity-25 border-white skill-icon-box" :class="{'active': isActiveSkill}">
    <router-link :to="link" :class="['d-flex flex-grow-1']">
      <div
          class="cursor-pointer d-flex flex-column align-items-center flex-grow-1 align-content-between justify-content-around">


        <div class="skill-orby">
          <svg height="70" width="70" style="transition: all ease-in-out 0.5s;
  transform: rotate(-90deg);">
            <circle cx="35" cy="35" r="30" stroke="rgba(0,0,0,0.1)" stroke-width="3" fill="transparent" />
            <circle cx="35" cy="35" r="30" stroke="lime" stroke-width="3" fill="rgba(255,255,255,0.1)"  :style="[`stroke-dashoffset: ${getPercent};`, `stroke-dasharray: 188`, 'transition: all ease-in-out 0.5s;']" />
          </svg>
          <McIcon :skill="skill" :animate="true"></McIcon>
        </div>

        <span class="text-center skill-level py-1 px-3">
          {{ getSkillLevel }}
        </span>
      </div>
    </router-link>

    <template #popper>
      <h6>{{ skillName }}</h6>
      <p class="mb-0">Current XP: {{ getCurrentXPFormatted }}</p>
      <p class="mb-0">Next Level: {{ getFormattedXPRequiredForNextLevel }}</p>
    </template>
  </v-tooltip>
</template>

<script>
import McIcon from "@/components/McIcon";
import {startCase} from 'lodash/string';
import {mapGetters} from "vuex";

export default {
  name: "SkillIconBox",
  methods: {
    startCase() {
      return startCase
    }
  },
  components: {McIcon},
  data() {
    return {
      p: 0
    }
  },
  props: {
    skill: {
      required: true,
    }
  },
  mounted() {
  },
  computed: {
    skillName() {
      return startCase(this.skill)
    },
    ...mapGetters({
      activeSkill: "user/activeSkill"
    }),
    getSkillLevel() {
      return this.$store.getters["user/getLevelForSkill"](
        this.skill
      );
    },
    isActiveSkill() {
      const activeSkillID = this.activeSkill;
      const skillList = this.$store.state.config.zones;

      if(!activeSkillID) return;

      const foundSkill = skillList.find(s => s.id === activeSkillID);

      return foundSkill && foundSkill.skill === this.skill;
    },

    getCurrentXPFormatted() {
      return new Intl.NumberFormat('en-gb').format(this.$store.getters["user/getCurrentXpForSkill"](
        this.skill
      ));
    },

    getFormattedXPRequiredForNextLevel() {

      const xpReq = this.$store.getters["user/getFormattedXpRequiredForNextLevel"](
        this.skill
      );



      if(!xpReq)
        return 'Maxed';

      return new Intl.NumberFormat('en-gb').format(
        this.$store.getters["user/getFormattedXpRequiredForNextLevel"](this.skill)
      );
    },

    getPercentToNextLevel() {
      return this.$store.getters["user/getPercentToNextLevel"](this.skill)
    },

    getPercent() {
      return Math.ceil((188 / 100) * (100 - this.getPercentToNextLevel));
    },

    link() {
      return {
        name: startCase(this.skill)
      }
    }
  }
}
</script>