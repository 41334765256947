<template>
  <div
      class="position-absolute w-100 h-100 bg-black left-0 top-0 z-index-110 pt-5 bg-opacity-75 d-flex justify-content-center align-items-center"
      @click="close">
    <div class="dialogue-box dialogue-box-model has-header d-flex justify-content-center m-2 mt-5" @click.stop="">
      <div class="dialogue-box-header">Register</div>
      <div class="dialogue-box-inner p-4">
        <p>
          Upgrade your account from a guest enter, just enter some accounts details below
        </p>

        <form @submit.prevent="registerUser" method="post" novalidate class="position-relative p-2">
          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="Username" id="register_username"
                   v-model="register.username"
                   :class="{'border-danger': errors.username}">
            <div class="text-danger small w-100" v-if="errors.username">{{ errors.username }}</div>
          </div>

          <div class="input-group mb-2">
            <input type="text" class="form-control" placeholder="Email" id="register_email"
                   v-model="register.email"
                   :class="{'border-danger': errors.email}">
            <div class="text-danger small w-100" v-if="errors.email">{{ errors.email }}</div>
          </div>

          <div class="input-group mb-2">
            <input type="password" class="form-control" placeholder="Password" id="register_password"
                   v-model="register.password"
                   :class="{'border-danger': errors.password}">
            <div class="text-danger small w-100" v-if="errors.password">{{ errors.password }}</div>
          </div>

          <div class="input-group mb-2">
            <input type="password" class="form-control" placeholder="Password"
                   id="register_password_confirm"
                   v-model="register.password_confirmation"
                   :class="{'border-danger': errors.password_confirmation}">
            <div class="text-danger small w-100" v-if="errors.password_confirmation">{{
                errors.password_confirmation
              }}
            </div>
          </div>

          <button class="btn w-100 btn-login btn-primary" type="submit">
            Register
          </button>
        </form>

      </div>

      <div class="dialogue-bg"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "UpgradeFromGuestDialogue",
  data: () => {
    return {
      register: {
        username: '',
        password: '',
        password_confirmation: '',
        email: ''
      },
      errors: {}
    }
  },
  methods: {
    registerUser() {
      this.$socket.emit("auth:upgrade from guest", this.register)
    },
    close() {
      this.$store.state.layout.upgradeFromGuestDialogue = false;

    },
  },
  sockets: {
    'REGISTER_SUCCESS'(token) {
      localStorage.setItem(process.env.VUE_APP_JWT_NAME, token);
      window.location.reload();
    },
    'register error'(errors) {
      this.errors = {...errors};
    },
  }
}
</script>