<template>
  <div class="grid-inventory w-100">
    <div class="grid-inventory-filter">
      <input type="text" v-model="filter" class="form-control bg-dark" placeholder="What are you looking for?">
    </div>
    <ul class="list-unstyled p-2 my-1 grid-inventory-inventory">
      <li v-for="(item) in cosmeticsFiltered" :key="item.id" class="m-2 d-inline-block" @click="equip(item)">
        <img :src="assetLink(item)" :alt="item.Cosmetic.name" style="width: 64px;height: 64px;" class="mc-item-image cursor-pointer" :class="{'bg-success': item.equipped}" />
      </li>
    </ul>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {snakeCase} from "lodash";

export default {
  name: 'Wardrobe',
  components: {},
  data() {
    return {
      filter: ''
    }
  },
  computed: {
    ...mapGetters({
      cosmetics: "user/cosmetics"
    }),
    cosmeticsFiltered() {
      return this.cosmetics.filter(c => c.Cosmetic.name.toLowerCase().includes(this.filter.toLowerCase()))
    }
  },
  methods: {
    assetLink(asset) {
      const assetName = snakeCase(asset.Cosmetic.name);
      return `/img/cosmetics/${assetName}.png`
    },
    equip(asset) {
      if(!asset.equipped)
        this.$socket.emit('auth:equip cosmetic', asset.id)
      else
        this.$socket.emit('auth:unequip cosmetic', asset.id)
    }
  }
}
</script>