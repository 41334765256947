<template>
    <li class="chat-message d-flex flex-row flex-wrap" v-if="message"
        :class="{'mentioned': mentioned, 'whisper': isWhisper}">
    <span v-if="isChatMod && !messageDeleted && !isSystem" @click="deleteMessage"
          class="me-1 d-inline-block cursor-pointer">
        <v-tooltip placement="left">
            <span>[x]</span>
            <template #popper>Delete</template>
        </v-tooltip>
    </span>
        <span v-if="isChatMod && !isSystem" @click="timeoutUser" class="me-1 d-inline-block cursor-pointer">
        <v-tooltip placement="left">
            <span>[t]</span>
            <template #popper>Timeout user</template>
        </v-tooltip>
    </span>

        <template v-if="author.title">
            <VTooltip>
                <div v-if="message?.From" :class="usernameColor" class="me-1 d-flex">
                    <McIcon :icon="message.From?.badge"
                            :size="12"
                            class="d-inline-block me-2"
                            v-if="author?.badge"/>
                    <span v-if="message.channel !== 'general' && (message.channel === `character-${characterId}`)"
                          class="text-muted">[whisper from]&nbsp;</span>
                    <span v-else-if="message.channel !== 'general' && (message.From.id === characterId)"
                          class="text-muted">[whisper to]&nbsp;</span>
                    <UserProfile :profile="`${author.username}`"/>
                    <span class="text-muted">:</span>
                </div>
                <template #popper>
                    {{ author.title }}
                </template>
            </VTooltip>
        </template>
        <span v-else-if="message.From" :class="usernameColor" class="d-flex">
      <span v-if="message.channel !== 'general' && (message.channel === `character-${characterId}`)" class="text-muted">[whisper from]&nbsp;</span>
      <span v-else-if="message.channel !== 'general' && (message.user_id === characterId)" class="text-muted">[whisper to]&nbsp;</span>
      <UserProfile :profile="`${author.username || author}`"/><span class="text-muted">:</span>
    </span>
        <!--    <component :is="'user-profile'" :profile="message" v-if="isSystem || message.Character.User.staff > 10" :class="{'text-white': type === 'level_99'}"></component>-->
        <!--    <component :is="'user-profile'" :message="formattedMessage"></component>-->
        <div :class="{
      'bg-primary bg-opacity-25 level_99': message.type === 'level_99',
      'refine_success': message.type === 'refine',
      'bg-opacity-25 refine_fail': message.type === 'refine_fail',
      'bg-opacity-25 font-primary': message.type === 'discover',
    }" class="d-flex flex-row">&nbsp;
            {{ formattedMessage }}
        </div>
    </li>
</template>
<style scoped>
.mentioned {
    background: #3a0131;
}

.whisper {
    background: #010141;
}

.level_99 {
    color: gold;
    background-color: #73541c !important;
}

.refine_success {
    color: lime;
    /*background-color: green !important;*/
}

.refine_fail {
    color: red;
}
</style>
<script>
import McIcon from "@/components/McIcon";
import UserProfile from "@/components/chat/UserProfile.vue"
import {mapGetters} from "vuex";

export default {
  name: 'ChatMessage',
  // eslint-disable-next-line vue/no-unused-components
  components: {McIcon, UserProfile},
  props: {
    message: {
      required: true,
    }
  },
  computed: {
    author() {
      if (this.message.channel === `channel-${this.characterId}`)
        return this.message.From

      if (this.message.channel !== 'general' && this.message.user_id === this.characterId)
        return this.message.To;

      return this.message?.From || this.message.from;
    },

    isSystem() {
      return this.message.system
    },
    isFriend() {
      if (!this.message.Character || !this.message.Character.User)
        return false;

      return false;
      // return this.store.user.relationships.findIndex(r => r.other_user_id === this.message.Character.id && r.type === 'friend') > -1
    },

    isChatMod() {
      return this.$store.state.user.user.staff > 0
    },

    messageDeleted() {
      return false;
    },
    isBlocked() {
      // if (!this.message.Character || !this.message.Character.User)
      //   return false;

      return false;
      // return this.store.user.relationships.findIndex(r => r.other_user_id === this.message.Character.id && r.type === 'block') > -1
    },
    messageIsFromStaff() {
      if (!this.author)
        return false;

      return this.author.User.staff >= 20
    },
    messageIsFromChatMod() {
      if (!this.author)
        return false;

      return this.author.User.staff >= 10
    },
    normalUser() {
      return this.author.User.staff === 0
    },
    mentioned() {
      return this.message.message.indexOf('@' + this.username) > -1
    },
    usernameColor() {
      return {
        'text-success': this.isFriend,
        'text-danger': this.messageIsFromStaff,
        'text-info': this.messageIsFromChatMod,
        'text-white': this.normalUser,
      }
    },
    type() {
      return this.message.type
    },
    ...mapGetters({
      username: "user/getCharacterName",
      characterId: "user/getCharacterId",
    }),
    isWhisper() {
      return this.message.channel === `character-${this.characterId}` || (this.message.channel !== 'general' && this.message.user_id === this.characterId)
    },
    formattedMessage() {
      let message = this.message.message.replaceAll('<', '&lt;').replaceAll('>', '&gt;').trim();


      if (message.indexOf('@') > -1) {
        let matched = message.match(/\B@\S+/g);

        if (!matched)
          return message;

        matched = matched.slice(0, 3);

        // matched.map(match => {
        //   message = message.replace(match, `<user-profile :profile="'${match}'" :is-mention="true" />`)
        // })
      }

      // if(message.indexOf('#') > -1) {
      //   let matched = message.match(/\B#\S+/g);
      //
      //   if (!matched)
      //     return message;
      //
      //   matched = matched.slice(0, 3);
      //
      //   matched.map(match => {
      //     message = message.replace(match, `<item :hash="'${match}'" :hide-quantity="true" :chat-message="true" :size="24" />`)
      //   })
      // }

      return message.trim();
    }
  },
  methods: {
    deleteMessage() {
      if (confirm(`Are you sure you want to delete this message? ${this.message.message}`) === true) {
        this.$socket.emit('delete message', this.message.id)
      }
    },
    banUser() {
      if (confirm(`Are you sure you want to timeout ${this.message.From.username} for 10 minutes?`) === true) {
        this.$socket.emit('chatban user', this.message.From.user_id)
      }
    },
    timeoutUser() {
      if (confirm(`Are you sure you want to timeout ${this.message.From.username} for 10 minutes?`) === true) {
        this.$socket.emit('timeout user', this.message.From.user_id)
      }
    },
  }
}
</script>