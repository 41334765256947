<template>
  <div id="grid-right-side" :class="{'open': inventoryIsOpen}">
    <div class="w-100  nav nav-tabs">
      <v-tooltip class="flex-grow-1 nav-item" :class="{'active': open === 'inventory'}">
        <router-link to="#" @click="open = 'inventory'" class="btn btn-ui nav-link"
                     :class="{'active': open === 'inventory'}">
          <img src="/img/items/Inventory.png"/>
        </router-link>
        <template #popper>
          Inventory
        </template>
      </v-tooltip>
      <v-tooltip class="flex-grow-1 nav-item">
        <router-link to="#" @click="open = 'equipment'" class="btn btn-ui nav-link"
                     :class="{'active': open === 'equipment'}">
          <img src="/img/items/Equip.png"/>
        </router-link>
        <template #popper>
          Equipment
        </template>
      </v-tooltip>
      <v-tooltip class="flex-grow-1 nav-item">
        <router-link :to="{name: 'SkinChooser'}" class="btn btn-ui nav-link" @click="open = 'wardrobe'"
                     :class="{'active': open === 'wardrobe'}">
          <img src="/img/items/Wardrobe.png"/>
        </router-link>

        <template #popper>
          Wardrobe
        </template>
      </v-tooltip>
      <v-tooltip class="flex-grow-1 nav-item">
        <router-link :to="{name: 'Quest'}" class="btn btn-ui nav-link">
          <div class="position-relative">
            <img src="/img/items/World.png"/>

            <span class="quest-status-dot" :class="{
            'bg-warning': status === 'in progress',
            'bg-lime': status === 'completed',
            'bg-info': status === 'available'
          }">
          </span>
          </div>
        </router-link>

        <template #popper>
          Quests - {{ status }}
        </template>
      </v-tooltip>

      <v-tooltip class="flex-grow-1 nav-item">
        <a href="#" class="btn btn-ui nav-link" @click="open = 'hiscores'"
           :class="{'active': open === 'hiscores'}">
          <img src="/img/sprites/gold_trophy.png" style="width: 32px;" class="image-pixelated"/>
        </a>

        <template #popper>
          View the Hiscores
        </template>
      </v-tooltip>
    </div>
    <div class="w-100">
      <div class="d-flex flex-row justify-content-around p-3">
        <v-tooltip>
          <div class="d-flex flex-row align-items-center cursor-pointer" @click="goToMarket()">
            <img src="/img/sprites/coin.png"/>

            <vue3autocounter ref='gold_counter'
                             :startAmount='coin.previous'
                             :endAmount='coin.current'
                             :duration='1'
                             :autoinit='true'/>
          </div>
          <template #popper>
            Currency - Go to Market
          </template>
        </v-tooltip>
        <v-tooltip>

          <div class="d-flex flex-row align-items-center">
            <img src="/img/sprites/flame.png"/>
            <vue3autocounter ref='heat_counter'
                             :startAmount='flame.previous'
                             :endAmount='flame.current'
                             :duration='1'
                             :autoinit='true'/>
          </div>
          <template #popper>
            Heat
          </template>
        </v-tooltip>
      </div>
    </div>
    <Inventory v-if="open === 'inventory'"/>
    <Equipment v-if="open === 'equipment'"/>
    <Wardrobe v-if="open === 'wardrobe'"/>
    <Hiscores v-if="open === 'hiscores'"/>
  </div>
</template>
<style scoped lang="scss">
.quest-status-dot {
  width: 10px;
  height: 10px;
  display: block;
  position: absolute;
  top: 0;
  right: 15px;
  border-radius: 10px;
}

.bg-lime {
  background: lime;
}
</style>
<script>
import Inventory from "@/components/Inventory";
import Equipment from "@/components/nav/RightSide/Equipment";
import Vue3autocounter from 'vue3-autocounter';

import {mapGetters} from "vuex";
import Wardrobe from "@/components/nav/RightSide/Wardrobe";
import Hiscores from "@/views/play/Hiscores.vue";

export default {
  components: {Hiscores, Wardrobe, Equipment, Inventory, Vue3autocounter},
  data() {
    return {
      open: 'inventory',
      coin: {
        current: 0,
        previous: 0,
      },
      flame: {
        current: 0,
        previous: 0,
      }
    }
  },
  mounted() {
    this.coin.current = this.currency;
    this.flame.current = this.heat;
    this.$refs.gold_counter.start();
    this.$refs.heat_counter.start();
  },
  sockets: {
    'CHARACTER_UPDATE'(character) {
      for (const stat in character) {
        if (stat === 'gold') {
          this.coin.previous = Number(this.coin.current);
          this.coin.current = Number(character[stat])
        } else if (stat === 'heat') {
          this.flame.previous = Number(this.flame.current);
          this.flame.current = Number(character[stat])
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      currency: "user/currency",
      heat: "user/heat",
      inventoryIsOpen: "layout/inventoryIsOpen",
      status: "user/questStatus",
      staff: "user/staffLevel"
    }),
  },
  methods: {
    goToMarket() {
      this.$router.push({
        name: 'MarketplaceHome'
      });
    }
  }
}
</script>