<template>
  <div class="w-100 overflow-scroll">
    <ul class="list-unstyled nav nav-pills justify-content-center mb-2">
      <li v-for="(skill, i) in categories" :key="i" class="cursor-pointer nav-item" @click="selectedCategory = skill">
        <a href="#" class="nav-link p-1 px-2" :class="{'active': selectedCategory === skill}" style="--bs-nav-pills-link-active-bg: white" v-tooltip="startCase(skill)">
          <McIcon :skill="skill"></McIcon>
        </a>
      </li>
    </ul>
    <div>
      <div class="table-responsive">
        <table class="table table-dark table-bordered table-hover">
          <thead>
            <tr>
              <th>Name</th>
              <th>Level</th>
              <th>XP</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(row, index) in results" :key="index" :style ="[this.character.username === row.username ? '--bs-table-bg: rgba(0,0,0,0.8);background:linear-gradient(5deg, transparent 26%,#ff02fb);--bs-table-hover-bg: rgba(255,255,255,0.2);' : '']">
            <td>
              <img src="/img/sprites/gold_trophy.png" v-if="index === 0" />
              <img src="/img/sprites/silver_trophy.png" v-if="index === 1" />
              <img src="/img/sprites/bronze_trophy.png" v-if="index === 2" />
              <span class="text-muted" v-if="![0,1,2].includes(index)">#{{ index + 1 }}</span> {{ row.username }}
            </td>
            <td>{{ row.level }}</td>
            <td>{{ formatNumber(row.xp) }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import McIcon from "@/components/McIcon.vue";
import {mapGetters} from "vuex";
import {startCase} from "lodash";

export default {
  components: {McIcon},
  data() {
    return {
      categories: [
        'total_level',
        'combat',
        'woodcutting',
        'mining',
        'fishing',
        'crafting',
        'smithing',
        'cooking',
        'refining',
      ],
      selectedCategory: 'total_level',
      results: [],
    }
  },
  watch: {
    selectedCategory(n) {
      this.$socket.emit('auth:hiscore', n);
    }
  },
  mounted() {
    this.$socket.emit('auth:hiscore', this.selectedCategory);
  },
  sockets: {
    HISCORE_RESULTS(data) {
      this.results = data;
    },
  },
  methods: {
    startCase,
    formatNumber(number) {
      return new Intl.NumberFormat().format(number)
    },
  },
  computed: {
    ...mapGetters({
      character: "user/character"
    }),
  }
}
</script>